@use '../abstract' as *;

/*----------------------------------------*/
/*  18. SLIDER CSS START
/*----------------------------------------*/

.slider{
    &__wrapper{
        & .swiper-slide-active {
            & span,
            & p,
            & a,
            & .slider__title{
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
    &__height{

        // min-height: 950px;

        // @media #{$lg}{
        //     min-height: 850px;
        // }
        // @media #{$md}{
        //     min-height: 750px;
        // }
        // @media #{$sm}{
        //     min-height: 1100px;
        // }
        // @media #{$xs}{
        //     min-height: 850px;
        // }


        min-height: 650px;
        @media #{$sm}{
                min-height: 1350px;
            }
            @media #{$xs}{
                min-height: 850px;
            }
        
    }
    &__bg{
       width: 100%;
       background-size: cover;
       background-position: center;
    }
    &__overlay{
        position: relative;
        &::after{
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba($color: $black-3, $alpha: .3);
        }
    }
    &__content{
        position: relative;
        z-index: 1;
        & span{
            font-size: 18px;
            color: $e-yellow;
            // margin-bottom: 12px;
            display: inline-block;
        }

        & p{
            font-size: 22px;
            line-height: 30px;
            padding-right: 160px;
            //margin-bottom: 28px;
            opacity: .7;
            color: $white;

            @media #{$md}{
                padding-right: 0;
            }
            @media #{$sm}{
                padding-right: 0;
            }
            @media #{$xs}{
                padding-right: 0;
            }
        }
        & > span,
        & p,
        & a{
            position: relative;
            z-index: 1;
            padding-right: 25px;
            transform: translateY(70px);
            transition: 1s;
            // opacity: 0;
        }
    }
    &__title{
        font-size: 70px;
        color: $white;
        line-height: 1.05;
        margin-bottom: 5px;

        @media #{$md}{
            font-size: 50px;
        }
        @media #{$sm}{
            font-size: 40px;
        }
        @media #{$xs}{
            font-size: 30px;
        }
        position: relative;
        z-index: 1;
        padding-right: 25px;
        transform: translateY(70px);
        transition: 1s;
        // opacity: 0;
        & span{
            font-size: 70px;
            color: $white;
            position: relative;
            margin-bottom: 0;
            @media #{$md}{
                font-size: 50px;
            }
            @media #{$sm}{
                font-size: 40px;
            }
            @media #{$xs}{
                font-size: 30px;
            }
			& img{
				position: absolute;
				left: -14px;
				bottom: 11px;
				z-index: -1;
				-webkit-animation: section-animation 3s infinite;
				animation: section-animation 3s infinite;
                @media #{$xs}{
                    height: 15px;
                    bottom: 0;
                }
			}
        }
    }
    &__btn{
        height: 50px;
        line-height: 48px;
        border: 2px solid rgba($color: $white, $alpha: .3);
        padding: 0 22px;
        background: transparent;

        &:hover{
            background: $white;
            color: $black-3;
            border-color: $white;
        }
    }
    &__nav{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height:100%;
        &-item{
            @include background();
            transition: height .3s ease-in-out;
            position: relative;

            &:hover{
                cursor: pointer;
            }
            &::after{
                position: absolute;
                content: '';
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: rgba($color: $e-orange-4, $alpha: 1);
            }
            &.blue-bg{
                &::after{
                    background: rgba($color: $e-blue-4, $alpha: 1);
                }
            }
            &.pink-bg{
                &::after{
                    background: rgba($color: $e-pink-3, $alpha: 1);
                }
            }
            &.green-bg{
                &::after{
                    background: rgba($color: $e-green-2, $alpha: 1);
                }
            }
            &.swiper-slide-thumb-active{
                
            }
        }
        &-icon {
            z-index: 3;
color: $white;
font-size: 30px;
padding-left: 15px;
        }
        &-content{
            position: relative;
            z-index: 1;
            height: 100%;

            padding: 23px 50px;
            
            & span{
                display: inline-block;
                font-size: 15px;
                color: $white;
                opacity: .8;
            }
            & h4{
                font-size: 22px;
                font-weight: 600;
                color: $white;
                margin-bottom: 0;
            }
        }
    }
}
.swiper-thumbs {
//	margin-bottom: 30px;
}
.slider__nav-item {
    // @media #{$lg , $md , $xs, $sm}{
    //     display: none;
    // }
    height:fit-content;
}

.swiper-fade .swiper-slide .swiper-slide {
	pointer-events: auto !important;
}