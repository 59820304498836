@use "../abstract" as *;

.grades {
    .inactive {
        color: #0e1133;
        background: #fff;
        border: #0e1133 2px solid;
    }

    &__item {
        position: relative;
        padding: 10px 20px;
        margin: 10px;
        width: fit-content;
        height: fit-content;
        @include box-shadow(0px 30px 40px 0px rgba(3, 24, 128, 0.2));
        @include border-radius(6px);
        backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-transition: transform 0.3s cubic-bezier(0.21, 0.6, 0.44, 2.18);
        -moz-transition: transform 0.3s cubic-bezier(0.21, 0.6, 0.44, 2.18);
        -ms-transition: transform 0.3s cubic-bezier(0.21, 0.6, 0.44, 2.18);
        -o-transition: transform 0.3s cubic-bezier(0.21, 0.6, 0.44, 2.18);
        transition: transform 0.3s cubic-bezier(0.21, 0.6, 0.44, 2.18);

        &:hover {
            @include transform(translateY(-5px) translate3d(0, -5px, 0));
            & .link-btn-2 {
                visibility: visible;
                opacity: 1;
                @media #{$xs} {
                    bottom: 0px;
                }
            }
        }
    }
    &__title {
        font-size: 18px;
        color: $white;

        margin-right: 10px;
    }
    &__icon {
        & svg {
            width: 50px;
            height: 50px;
            & path {
                fill: $white;
            }
        }
    }
    &__content {
        position: relative;
        display: flex;
        width: fit-content;
        justify-content: center;
        align-items: center;

        & .link-btn-2 {
            position: relative;
            visibility: hidden;
            opacity: 0;
            color: $white;

            @media #{$lg} {
                left: 15px;
            }
        }
    }
}

.chapter-item {
    font-size: 20px;
    font-weight: 700;
    color: #0e1133;
    box-shadow: none;
    background: #2b4eff0c;
    padding: 15px 30px;
    margin-bottom: 50px;
    // border:2px solid #2b4eff;
    border-radius: 4px;
    transition: all 0.3s ease-out 0s;

    &:hover {
        background: #2b4eff;
        color: #ffffff;
        box-shadow: 0px 10px 24px 0px rgb(4 23 118 / 30%);

        .index {
            color: #ffffff;
        }
    }

    .index {
        color: #2b4eff;
        margin-right: 10px;
    }
}

.supplementary-exercice-item {
    font-size: 20px;
    font-weight: 700;
    color: #0e1133;
    box-shadow: none;
    padding: 13px 30px;
    margin-bottom: 50px;
    border: 2px solid #2b4eff;
    border-radius: 4px;
    transition: all 0.3s ease-out 0s;

    &:hover {
        background: #2b4eff;
        color: #ffffff;
        box-shadow: 0px 10px 24px 0px rgb(4 23 118 / 30%);

        .index {
            color: #ffffff;
        }
    }

    .index {
        color: #2b4eff;
        margin-right: 10px;
    }
}

.activity {
    //main color for this item
    $main-item-color: $e-purple;

    &__item {
        position: relative;
        z-index: 1;
        &-inner {
            padding: 28px 50px;
            @include border-radius(6px);
            @include box-shadow(0px 30px 50px 0px rgba(1, 11, 60, 0.1));
            transition: all 0.2s ease;

            @media #{$sm} {
                padding-left: 20px;
                padding-right: 20px;
            }
            @media #{$xs} {
                padding-left: 30px;
                padding-right: 30px;
            }

            &:hover {
                @include box-shadow(0px 30px 40px 0px rgba($color: $main-item-color, $alpha: 0.2));
            }
        }

        &::after {
            position: absolute;
            content: "";
            top: auto;
            bottom: 0;
            left: -3px;
            width: 10%;
            height: 0%;
            background: $main-item-color;
            @include border-radius(6px);
            z-index: -1;
        }
        &:hover,
        &.active {
            &::after {
                top: 0;
                bottom: auto;
                height: 100%;
            }

            .index {
                color: $main-item-color;
            }
            .title {
                & a {
                    color: $main-item-color;
                }
            }
        }

        .title {
            font-size: 22px;
            font-weight: 600;
            margin-bottom: 0;
        }

        .index {
            color: $main-item-color;
        }

        .more {
            @media #{$xs} {
                margin-top: 15px;
            }
            & .link-btn {
                color: $black;
                &:hover {
                    color: $main-item-color;
                }
            }
        }
        .details {
            & h3 {
                font-size: 26px;
                font-weight: 700;
                margin-bottom: 15px;
            }
            & p {
                font-size: 18px;
                line-height: 28px;
                color: $e-text-4;
            }
        }

        .meta {
            margin-bottom: 5px;
            & span {
                display: inline-block;
                position: relative;
                padding-right: 7px;
                margin-right: 7px;
                &:not(:last-child)::after {
                    position: absolute;
                    content: "";
                    right: 0;
                    top: 5px;
                    width: 1px;
                    height: 14px;
                    background: $body-text-color;
                }
            }
        }
    }
}

//simulation

.simulation {
    //main color for this item
    $main-item-color: $e-skyblue;

    &__item {
        position: relative;
        z-index: 1;
        &-inner {
            padding: 10px 50px;
            @include border-radius(6px);
            @include box-shadow(0px 30px 50px 0px rgba(1, 11, 60, 0.1));
            transition: all 0.2s ease;

            @media #{$sm} {
                padding-left: 20px;
                padding-right: 20px;
            }
            @media #{$xs} {
                padding-left: 30px;
                padding-right: 30px;
            }

            &:hover {
                @include box-shadow(0px 30px 40px 0px rgba($color: $main-item-color, $alpha: 0.2));
            }
        }

        &::after {
            position: absolute;
            content: "";
            top: auto;
            bottom: 0;
            left: -3px;
            width: 10%;
            height: 0%;
            background: $main-item-color;
            @include border-radius(6px);
            z-index: -1;
        }
        &:hover,
        &.active {
            &::after {
                top: 0;
                bottom: auto;
                height: 100%;
            }

            .index {
                color: $main-item-color;
            }
            .title {
                color: $main-item-color;
            }
        }

        .title {
            font-size: 22px;
            font-weight: 600;
            margin-bottom: 0;
        }

        .index {
            color: $main-item-color;
        }

        .more {
            @media #{$xs} {
                margin-top: 15px;
            }
            & .link-btn {
                color: $black;
                &:hover {
                    color: $main-item-color;
                }
            }
        }
        .details {
            & h3 {
                font-size: 26px;
                font-weight: 700;
                margin-bottom: 15px;
            }
            & p {
                font-size: 18px;
                line-height: 28px;
                color: $e-text-4;
            }
        }

        .meta {
            margin-bottom: 5px;
            & span {
                display: inline-block;
                position: relative;
                padding-right: 7px;
                margin-right: 7px;
                &:not(:last-child)::after {
                    position: absolute;
                    content: "";
                    right: 0;
                    top: 5px;
                    width: 1px;
                    height: 14px;
                    background: $body-text-color;
                }
            }
        }
    }
}

.skip-button {
    background: transparent;
    color: $e-orange;

    &:hover {
        color: $e-blue;
    }
}

.terms {
    font-size: 16px;
    line-height: 1.5;

    p {
        margin-bottom: 20px;
    }

    ol {
        margin-bottom: 20px;
    }

    ul {
        margin-bottom: 20px;
        padding-left: 20px;

        li {
            margin-bottom: 10px;
            list-style: circle;
            font-weight: normal;
        }
    }

    li {
        font-weight: bold;
        margin-bottom: 10px;
        list-style: decimal;
    }
}

.mcq_maker {
    input {
     border-radius: 20px;  
     background: transparent; 
border:grey 1px solid ;   }

input[type="radio"]::before {
    background-color: $e-green;
}
    .remove {
        background: transparent;
        color: red;
        padding: 10px;
        &:hover {
            background: rgba($color: red, $alpha: 0.2);
        }
    }

    .choice-item {
        border-bottom: grey 1px solid;
        padding: 3px;
    }

    .correct-answer{
        background:  rgba($color: $e-green, $alpha: 0.2);
        border: $e-green 2px solid;

        input::placeholder{
            color: inherit;
        }
    }

    .add-choice{ color: #2b4eff; background: transparent;}
    .accordion__button {
        background: none;
        display: flex;
        justify-content: center;
       
        color: #0e1133;
        box-shadow: none;
        background: #f3f4f8;
        padding: 0;
    }
    .accordion__button::before {
        margin: 22px;
    }
    .accordion-header {
        width: 100%;
    }

    .accordion__panel {
        padding: 0px;
        border: 1px solid #edeef2;
    }

    .accordion-button {
        font-size: 20px;
        font-weight: 700;
        padding: 19px 30px 17px 30px;

    }

    .accordion__item {
        margin-bottom: 10px;
    }


    .accordion__item + .accordion__item {
        border-top: 0px;
    }

    .accordion-button:not(.collapsed)::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
    }
}













