@use '../abstract' as *;

/*----------------------------------------*/
/*  16. TESTIMONIAL CSS START
/*----------------------------------------*/

.testimonial{
    &__area{
        @include background();
    }
    &__overlay{
        position: relative;
        &::after{
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba($color: $e-blue, $alpha: .84);
        }
    }
    &__slider{
        .swiper-button-next, .swiper-button-prev {
            width: inherit;
        }
        .swiper-button-next::after, .swiper-button-prev::after {
            display: inline-block;
            font-size: 14px;
            width: 50px;
            height: 50px;
            line-height: 46px;
            text-align: center;
            @include border-radius(50%);
            border: 2px solid rgba($color: $white, $alpha: .2);
            background: transparent;
            color: $white;
            &:hover{
                background: $white;
                border-color: $white;
                color: $e-blue;
            }
        }
        & .swiper-nav{
            & i{
                display: inline-block;
                font-size: 14px;
                width: 50px;
                height: 50px;
                line-height: 46px;
                text-align: center;
                @include border-radius(50%);
                border: 2px solid rgba($color: $white, $alpha: .2);
                background: transparent;
                color: $white;
                &:hover{
                    background: $white;
                    border-color: $white;
                    color: $e-blue;
                }
            }
            &::after{
                display: none;
            }
        }
        &-3{
            & .testimonial-nav{
                width: 290px;
                & .swiper-slide{
                    width: 70px;
                    & img{
                        width: 70px;
                        height: 70px;
                        @include border-radius(50%);
                    }

                    &.swiper-slide-thumb-active{
                        & .testimonial__nav-thumb{
                            &::after{
                                border-color: rgba($color: $white, $alpha: .3);
                            }
                        }
                    }
                }
            }
            & .swiper-container{
                margin-left: inherit;
                margin-right: inherit;
            }
        }
    }
    &__nav{
        &-thumb{
            position: relative;
            width: 70px;
            height: 70px;
            margin: 10px;
            img {
                border-radius: 50%;
            }
            &::after{
                position: absolute;
                content: '';
                left: -10px;
                top: -10px;
                width: calc(100% + 20px);
                height: calc(100% + 20px);
                border: 1px solid transparent;
                @include border-radius(50%);
                border-color: rgba($color: $white, $alpha: .3);
            }
        }
    }
    &__item{
        &-3{
            & p{
                font-size: 24px;
                line-height: 40px;
                color: $white;
            }
        }
    }
    &__title{
        font-size: 40px;
        color: $white;
    }
    &__thumb{
        margin-bottom: 30px;    
        & img{
            width: 80px;
            height: 80px;
            @include border-radius(50%);
        }
        &-3{
            height: 300px;
            width: 100%;
            & iframe{
                width: 100%;
                height: 100%;
            }
        }
    }
    &__content{
        & p{
            font-size: 30px;
            color: $white;
            line-height: 44px;
            font-weight: 500;
            padding: 0 290px;
            margin-bottom: 10px;

            @media #{$lg}{
                padding: 0 150px;
            }
            @media #{$md}{
                padding: 0 80px;
            }
            @media #{$sm}{
                padding: 0 80px;
            }
            @media #{$xs}{
                font-size: 20px;
                line-height: 33px;
                padding: 0 80px;
            }
        }
    }
    &__info{
        & h4{
            font-size: 20px;
            color: $white;
            margin-bottom: 0;
        }
        & span{
            font-size: 14px;
            color: $white;
            opacity: .7;
        }
        &-2{
            & h4{
                font-size: 22px;
                color: $white;
                display: inline-block;
            }
            & span{
                font-size: 18px;
                color: $grey-6;
            }
        }
    }
    &__video{
        @include border-radius(6px);
        @include box-shadow(0px 30px 70px 0px rgba(0, 32, 29, 0.14));

        @media #{$md}{
            margin-left: 0;
            margin-top: 50px;
        }
        @media #{$sm}{
            margin-left: 0;
            margin-top: 50px;
        }
        @media #{$xs}{
            margin-left: 0;
            margin-top: 50px;
        }
        &-icon{
            & span{
                display: inline-block;
                width: 60px;
                height: 60px;
                line-height: 65px;
                @include border-radius(50%);
                background: $e-skyblue;
                text-align: center;
                i {
                    font-size: 30px;
                    color: #fff;
                }
            }
        }
        &-content{
            background: $white;
            padding: 30px 40px;
        }
        &-text{
            & h4{
                font-size: 26px;
            }
            & p{
                color: $e-text-4;
                margin-bottom: 0;
            }
        }
    }
}