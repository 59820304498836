

.Theo {
  width: 100%;
  box-shadow: 0px 30px 50px 0px rgba(1, 11, 60, 0.1);
  border-radius: 10px;
  overflow: hidden;
  margin: 20px 0;

}

/* .Theo .page-controls {
  position: absolute;
  top: 5%;
  left: 50%;
  background: white;
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity ease-in-out 0.2s;
  box-shadow: 0px 30px 50px 0px rgba(1, 11, 60, 0.1);
  z-index: 30;
}

.Theo .page-controls span {
  font: inherit;
  font-size: 0.8em;
  padding: 0 0.5em;
}

.Theo .page-controls button {
  width: 44px;
  height: 44px;
  background: white;
  border: 0;
  font: inherit;
  font-size: 0.8em;
  border-radius: 5px;
}

.Theo .page-controls button:enabled:hover {
  cursor: pointer;
}

.Theo .page-controls button:enabled:hover,
.Theo .page-controls button:enabled:focus {
  background-color: #e6e6e6;
}

.Theo .page-controls button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.Theo .page-controls button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
} */

.Theo .controls {
  padding: 10px 20px;
  position: relative;
}

.Theo .Theo__container__document button a {
  background: transparent;
  border: none;
}

.Theo .controls .preview-button {
  background: blue;
}

@media print {
  .Theo {
    display: none;
  }
}

.Theo__container__document:hover .page-controls {
  opacity: 1;
}


.Theo__container__document {
  margin:0;
  user-select: none;
  

width: 100%;
  min-height: 60vh;
  position: relative;
  overflow: auto;
  background: #fff;
}

.Theo__container__document .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px !important;
}

.Theo__container__document .react-pdf__Page {
  background: #fff;
  margin: 0;
}

.Theo__container__document .react-pdf__Page canvas {
  height: auto !important;
}


/* Fullscreen styles */
.Theo .fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  transition: all 0.5s ease-in-out; /* Transition for smooth effect */
background-color: black;
}
