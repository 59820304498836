




.quiz-container {
    max-width: 100%;
    min-width: 250px;
    background: #ffffff;
    border-radius: 4px;
    margin: 100px 0;
    padding: 30px 60px;
    min-height:250px;
    box-shadow: 0px 30px 50px 0px rgb(1 11 60 / 10%);
  }
  
  .quiz-container .active-question-no {
    font-size: 32px;
    font-weight: 500;
    color: #2b4eff;
  }
  
  .quiz-container .total-question {
    font-size: 16px;
    font-weight: 500;
    color: #e0dee3;
  }
  
  .quiz-container h2 {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
  }
  
  .quiz-container ul {
    margin: 20px 0;
  }
  
  .quiz-container ul li {
    text-decoration: none;
    list-style: none;
    color: #2d264b;
    font-size: 16px;
    background: #f3f4f8;
    /* border: 1px solid #eaeaea; */
    border-radius: 6px;
    padding: 11px;
    margin-top: 15px;
    cursor: pointer;
  }
  
  .quiz-container ul .wrong-answer {
    background:#e407074d;
    /* border: 1px solid #e40707; */
    color: #e40707;
  }
  
  .quiz-container ul .correct-answer {
      background:#0dd84d4d;
      /* border: 1px solid #0dd84d; */
      color: #0dd84d;
    }
    
  
  /* 
  .quiz-container button {
    background: linear-gradient(90.04deg, #800080 0.03%, #03508b 99.96%);
    border-radius: 9px;
    font-size: 18px;
    color: #ffffff;
    padding: 10px 42px;
    outline: none;
    border: none;
    cursor: pointer;
    margin-top: 15px;
  } */
  
  .quiz-container button:disabled {
    background: #e7e8e9;
    color: #9fa3a9;
    cursor: not-allowed;
    box-shadow:none;
  }
  
  .flex-right {
    display: flex;
    justify-content: flex-end;
  }
  
  .result h3 {
    font-size: 24px;
    letter-spacing: 1.4px;
    text-align: center;
  }
  
  .result p {
    font-size: 16px;
    font-weight: 500;
  }
/*   
  .result p span {
    color: #800080;
    font-size: 22px;
  } */