.spinner {
    animation: rotate 2s linear infinite;
    z-index: 99;

    width: 45px;
    height: 45px;

    margin-top: 60px;
    margin-bottom: 60px;
}

.path {
    stroke: #294cff;
    stroke-linecap: round;
    animation: dash 1s ease-in-out infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}