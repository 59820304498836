
@use '../abstract' as *;

/*----------------------------------------*/
/*  03. HERO CSS START
/*----------------------------------------*/

.hero{
    &__height{
        min-height: 950px;

        @media #{$lg}{
            min-height: 850px;
        }
        @media #{$md}{
            min-height: 750px;
        }
        @media #{$sm}{
            min-height: 1100px;
        }
        @media #{$xs}{
            min-height: 850px;
        }
        &-2{
            @media #{$sm}{
                min-height: 1350px;
            }
            @media #{$xs}{
                min-height: 850px;
            }
        }
    }
    &__title{
        font-size: 60px;
        line-height: 1;

        @media #{$xl}{
            font-size: 55px;
        }
        @media #{$lg}{
            font-size: 45px;
        }
        @media #{$md}{
            font-size: 35px;
        }
        @media #{$sm}{
            font-size: 50px;
        }
        @media #{$xs}{
            font-size: 30px;
        }
        & span{
            &:first-child{
                font-weight: 300;
                display: block;
            }
            &.yellow-shape{
                position: relative;
                z-index: 1;
                & img{
                    position: absolute;
                    bottom: 22px;
                    left: -8px;
                    z-index: -1;
                    -webkit-animation: section-animation 3s infinite;
                    animation: section-animation 3s infinite;

                    @media #{$md}{
                        bottom: 12px;
                        height: 40% !important;
                    }
                }
            }
        }
        &-2{
            margin-bottom: 20px;
        }
    }
    &__content{
        z-index: 2;
        & p{
            font-size: 22px;
            color: $black;
            padding-right: 130px;
            line-height: 30px;
            margin-bottom: 28px;
            @media #{$lg}{
                padding-right: 30px;
            }
            @media #{$md}{
                padding-right: 0px;
            }
            @media #{$sm}{
                padding-right: 0px;
            }
            @media #{$xs}{
                padding-right: 0px;
            }
        }
        &-2{
            & h4{
                font-size: 22px;
                margin-bottom: 0;
            }
            & p{
                color: $e-text-4;
                font-size: 16px;
                margin-bottom: 37px;
            }
        }
    }
    &__thumb{
        z-index: 1;
        &-big{
            position: relative;
            & img{
                max-width: 400px;
                max-height: 460px;

                @include border-radius(40px 4px 40px 4px);
                @include box-shadow(0px 30px 60px 0px rgba(1, 11, 60, 0.14));

                @media #{$md}{
                    width: 100%;
                }
                @media #{$sm}{
                    margin-top: 50px;
                    width: 100%;
                }
                @media #{$xs}{
                    margin-top: 50px;
                    width: 100%;
                }
            }
        }
        &-sm{
            
            @media #{$xl}{
                margin-left: -50px;
            }
           
            
            @media #{$lg}{
                margin-left: -160px;
                // margin-top: -95px;
            }
            & img{
                max-width: 200px;
                max-height: 240px;
                @include border-radius(4px 40px 4px 40px);
                @include box-shadow(0px 30px 60px 0px rgba(1, 11, 60, 0.14));
            }
        }
        &-shape{
            & img{
                position: absolute;
                z-index: -1;

                &.hero-1-dot{
                    bottom: -25px;
                    left: -30px;
                    -webkit-animation: hero-dot-1 5s linear 0s infinite alternate;
                    -moz-animation: hero-dot-1 5s linear 0s infinite alternate; 
                    -o-animation: hero-dot-1 5s linear 0s infinite alternate;
                    animation: hero-dot-1 5s linear 0s infinite alternate;
                }
                &.hero-1-circle-3{
                    bottom: -47px;
                    right: 1%;
                }
                &.hero-1-circle-4{
                    top: -50px;
                    right: 10%;
                }
            }
        }
        &-2{
            position: relative;
            z-index: 1;
            & img{


                &.hero-big{


    max-height: 600px;
    object-fit: contain;
                    @media #{$xxxl}{
                        margin-left: 90px;

                    }
                    @media #{$xl}{
                        width: 100%;
                        max-height: 600px;

                    }
                    @media #{$lg}{
                        width: 100%;
    max-height: 600px;

                    }
                    @media #{$md}{
    max-height: 440px;
                        width: 100%;
                    }
                    @media #{$sm}{
                        width: 100%;
                        max-height: 440px;

                    }
                    @media #{$xs}{
                        max-height: 440px;

                        width: 100%;
                    }
                }
                &.hero-shape-purple{
                    position: absolute;
                    left: 0;
                    bottom: 0%;
                    z-index: -1;
                    width: 90%;

                    @media #{$xl}{
                        width: 100%;
                        bottom:2%;
                    }
                    @media #{$lg}{
                        width: 100%;
                        bottom:2%;

                    }
                    @media #{$md}{
                        width: 100%;
                        bottom: 19%;

                        
                    }
                    @media #{$sm}{
                        width: 100%;
                        bottom: 19%;

                    }
                    @media #{$xs}{
                        width: 100%;
                        bottom: 19%;

                    }
                }
            }
        }
    }
    &__quote{
        position: absolute;
        bottom: 60px;
        right: -200px;
        padding: 15px 25px;
        padding-bottom: 20px;
        background: $white;
        @include border-radius(4px);
        @include box-shadow(0px 30px 60px 0px rgba(1, 11, 60, 0.14));

        @media #{$xl}{
            right: -145px;
        }
        @media #{$lg}{
            right: 60px;
            bottom: -50px;
        }
        @media #{$md}{
            right: 30px;
            bottom: -50px;
        }
        @media #{$sm}{
            right: 30px;
            bottom: -50px;
        }
        @media #{$xs}{
            right: 15px;
            bottom: -50px;
        }
        & span{
            font-size: 14px;
            color: $e-text-2;
        }
        & h4{
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 0;
            line-height: 1;
        }

        &-animation{
            animation-duration: 2.5s;
            animation-fill-mode: both;
            animation-iteration-count: infinite;
            animation-name: hero-bounce;
        }
    }
    &__shape{
        & img{
            position: absolute;
            &.hero-1-circle{
                top: 25%;
                left: 5%;
                -webkit-animation: hero-circle-1 6s linear 0s infinite alternate;
                -moz-animation: hero-circle-1 6s linear 0s infinite alternate; 
                -o-animation: hero-circle-1 6s linear 0s infinite alternate;
                animation: hero-circle-1 6s linear 0s infinite alternate; 
            }
            &.hero-1-circle-2{
                bottom: 29%;
                left: 0;
            }
            &.hero-1-dot-2{
                right: 0;
                bottom: 38%;
            }
        }
    }
    &__search{
        &-input{
            position: relative;
            width: 370px;
            @media #{$xs}{
                width: 100%;
            }
            & input{
                width: 100%;
                height: 54px;
                line-height: 54px;
                padding: 0 24px;
                padding-right: 35px;
                font-size: 14px;
                @include border-radius(4px);
                outline: none;
                border: none;

                &::placeholder{
                    color: $e-text-7;
                }
            }
            & button{
                position: absolute;
                top: 53%;
                right: 24px;
                @include transform(translateY(-50%));
                font-size: 18px;
                background: transparent;
                & i{
                    &::after{
                        color: $black;
                        opacity: 1;
                    }
                    &::before{
                        color: $e-text-1;
                    }
                }
            }
        }
        & p{
            font-size: 16px;
            color: $e-text-3;
        }
    }
    &__promotion{
        position: absolute !important;
        left: -7% !important;
        top: 44% !important;
        @include border-radius(4px);
        @include box-shadow(0px 30px 60px 0px rgba(1, 11, 60, 0.14));
        padding: 15px 20px;
        padding-bottom: 10px;
        @media #{$md}{
            left: 20% !important;
            top: 91% !important;
            &.education{
                bottom: 22% !important;
                top: auto !important;
                left: -11% !important;
            }

        }
        @media #{$sm}{       
            left: 27% !important;
            top: 91% !important;
            &.education{
                bottom: 2% !important;
                top: auto !important;
                left: -11% !important;
            }
        }
        @media #{$xs}{
            left: 9% !important;
            top: 90% !important;
            &.education{
                bottom: 2% !important;
                top: auto !important;
                left: -11% !important;
            }
        }
        &.education{
            bottom: 22% !important;
            top: auto !important;
            left: -11% !important;
        }
        &-icon{
            & span{
                display: inline-block;
                width: 36px;
                height: 36px;
                text-align: center;
                line-height: 36px;
                @include border-radius(50%);
                background: #ff7921;
                & svg{
                    height: 24px;
                    fill: $white;
                }
                &.cap{
                    background: #5773ff;
                }
            }
        }
        &-text{
            & h5{
                font-size: 16px;
                margin-bottom: 0;

            }
            & p{
                font-size: 14px;
                color: $e-text-2;
                margin-bottom: 0;
            }
        }
    }
    &__class{
        position: absolute;
        left: auto !important;
        top: auto !important;
        bottom: 33%;
        right: -12%;
        @include box-shadow(0px 30px 60px 0px rgba(1, 11, 60, 0.14));
        background: rgba($color: $white, $alpha: .9);
        @include border-radius(4px);
        padding: 20px;

        @media #{$xl}{
            right: -5%;
        }
        @media #{$lg}{
            right: 1%;
            bottom: 15%;
        }
        &-thumb{
            & img{
                width: 34px;
                height: 34px;
                @include border-radius(50%);
            }
        }
        &-text{
            & h5{
                font-size: 16px;
                margin-bottom: 0;
            }
            & p{
                font-size: 14px;
                color: $e-text-2;
                margin-bottom: 0;
            }
            & a{
                display: inline-block;
                height: 30px;
                line-height: 30px;
                text-align: center;
                color: $white;
                font-weight: 500;
                padding: 0 15px;
                @include border-radius(4px);
                background: #e233fb;
            }
        }
    }
    &__mic{
        position: absolute;
        top: 30%;
        right: 10%;
        animation-duration: 2.5s;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
        animation-name: hero-bounce;
        & span{
            display: inline-block;
            width: 50px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            background: #ff3c4e;
            @include box-shadow(0px 14px 30px 0px rgba(131, 2, 14, 0.2));
            @include border-radius(6px);
            & svg{
                height: 28px;
                fill: $white;
            }
        }
    }
}


@keyframes hero-dot-1 {
    0% {
        transform: translateY(-100px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes hero-bounce {
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
    40% {transform: translateY(-30px);}
    60% {transform: translateY(-15px);}
}

@keyframes hero-circle-1{
    0%{
        @include transform(rotate(0deg));
    }
    100%{
        @include transform(rotate(360deg));
    }
}


.hero__content-wrapper.mt-90 {
    @media #{$xs}{
        margin-top: 160px;
    }
    @media #{$sm}{
        margin-top: 125px;
    }
}