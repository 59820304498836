.uploadIcon {
    font-size: 1.2rem;
    font-weight: bold;
    animation: uploading .7s 0s linear infinite;
}

@keyframes uploading {
    0% {
        color: #fbfbfb;
    }

    30% {
        color: #dcf1ff;
    }

    100% {
        color: #ffffff;
        transform: rotate(360deg);
    }
}